export abstract class LocalStorage {
  private static readonly llaveCompaniaActiva = 'selectedCompanie';
  private static readonly llaveAsociarSiesa = 'asociaSiesa';

  static getAsociaSiesa() {
    const valor = localStorage.getItem(this.llaveAsociarSiesa) || '';
    return valor === 'true';
  }
  static setAsociaSiesa(valor: string) {
    localStorage.setItem(this.llaveAsociarSiesa, valor);
  }

  static getCompaniaActiva() {
    return localStorage.getItem(this.llaveCompaniaActiva) || '';
  }
  static setCompaniaActiva(valor: string) {
    localStorage.setItem(this.llaveCompaniaActiva, valor);
  }
}
